import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class AclsService {
  getCategories() {
    return axios
      .get(`${config["apiUrl"]}acls/category`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getActiveCategories() {
    return axios
      .get(`${config["apiUrl"]}acls/category/active`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getCategoriesAcls() {
    return axios
      .get(`${config["apiUrl"]}acls/category/acls`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addCategory(bodyParms) {
    return axios
      .post(`${config["apiUrl"]}acls/category`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateCategory(categoryId, bodyParms) {
    return axios
      .put(`${config["apiUrl"]}acls/category/${categoryId}`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getAcls() {
    return axios
      .get(`${config["apiUrl"]}acls`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getActiveAcls() {
    return axios
      .get(`${config["apiUrl"]}acls/active`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addAcl(bodyParms) {
    return axios
      .post(`${config["apiUrl"]}acls`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateAcl(aclId, bodyParms) {
    return axios
      .put(`${config["apiUrl"]}acls/${aclId}`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getGroups() {
    return axios
      .get(`${config["apiUrl"]}acls/group`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getActiveGroups() {
    return axios
      .get(`${config["apiUrl"]}acls/group/active`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getGroupAcls(groupId) {
    return axios
      .get(`${config["apiUrl"]}acls/group/${groupId}/acls`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  addGroup(bodyParms) {
    return axios
      .post(`${config["apiUrl"]}acls/group`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateGroup(groupId, bodyParms) {
    return axios
      .put(`${config["apiUrl"]}acls/group/${groupId}`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getAclsByCategories() {
    return axios
      .get(`${config["apiUrl"]}acls/byCategories`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  setGroupAcls(groupId, bodyParms) {
    return axios
      .post(`${config["apiUrl"]}acls/group/${groupId}/acls`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getUsersList() {
    return axios
      .get(`${config["apiUrl"]}acls/usersList`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  setUserAcls(userId, bodyParms) {
    return axios
      .post(`${config["apiUrl"]}acls/user/${userId}/acls`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  setUserGroup(userId, groupId) {
    return axios
      .post(
        `${config["apiUrl"]}acls/user/${userId}/group/${groupId}`,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getUserAcls(userId) {
    return axios
      .get(`${config["apiUrl"]}acls/user/${userId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getAclGroups(aclId) {
    return axios
      .get(`${config["apiUrl"]}acls/${aclId}/groups`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  setAclGroups(aclId, bodyParms) {
    return axios
      .post(`${config["apiUrl"]}acls/${aclId}/groups`, bodyParms, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}

export default new AclsService();
