<template>
  <div :style="{ position: 'relative' }">
    <Loading :active.sync="loading" :isFullPage="false"></Loading>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <div>
      <div
        class="p-d-flex p-jc-row p-ai-center p-ml-3 acls-list"
        v-for="group in groups"
        :key="group.id"
      >
        <div>
          <InputSwitch
            v-model="aclGroups[group.id]"
            :id="acl.id"
            style="'top: 3px;"
          />
        </div>
        <div class="p-ml-2">{{ group.name }}</div>
      </div>
    </div>
    <divider />
    <div class="p-d-flex p-jc-end">
      <Button
        :label="'Guardar'"
        icon="pi pi-check"
        class="p-button-success p-mr-2"
        @click="save"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger"
        @click="cancel"
      />
    </div>
  </div>
</template>
<script>
import aclsService from "../../services/acls.service";
import loading from "vue-loading-overlay";
export default {
  name: "setAclsToGroup",
  components: {
    Loading: loading,
  },
  props: ["acl"],
  watch: {
    acl() {
      if (this.acl != undefined) {
        this.getInfo();
      }
    },
  },
  data() {
    return {
      loading: false,
      categoriesAcl: [],
      groups: [],
      aclGroups: [],
    };
  },
  productService: null,
  async created() {
    if (this.acl != undefined) {
      this.getInfo();
    }
  },
  methods: {
    async getInfo() {
      this.loading = true;
      this.groups = await aclsService.getActiveGroups();
      this.aclGroups = await this.getGroupAcls();
      return (this.loading = false);
    },
    async getGroupAcls() {
      return aclsService.getAclGroups(this.acl.id).then((response) => {
        let groups = {};
        response.map((group) => {
          groups[group.id] = true;
        });
        return groups;
      });
    },
    cancel() {
      return this.$emit("cancel");
    },
    save() {
      this.loading = true;
      let groupsChecked = [];
      for (const groupId in this.aclGroups) {
        if (this.aclGroups[groupId] === true) {
          groupsChecked.push(parseInt(groupId));
        }
      }

      return aclsService
        .setAclGroups(this.acl.id, { groups: groupsChecked })
        .then((response) => {
          this.loading = false;
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao alterar os grupos da acl",
              detail: "",
              life: 3000,
            });
          }

          this.$toast.add({
            severity: "success",
            summary: `Grupos da Acl alterados com sucesso`,
            detail: ``,
            life: 3000,
          });

          return this.$emit("setAclGroupsOk");
        });
    },
  },
};
</script>
<style>
.category {
  background-color: #ff9811;
  height: 30px;
  font-size: 16px;
}
.acls-list {
  height: 30px;
}
</style>
