<template>
  <div class="card p-shadow-6">
    <h1>Acls</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <div style="height: 65vh">
      <DataTable
        :value="listAcls"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="category.name"
        responsiveLayout="scroll"
        removableSort
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
        :autoLayout="true"
      >
        <template #empty> Não existem Acls. </template>
        <template #loading> A carregar. Por favor Aguarde... </template>
        <template #header>
          <div
            class="table-header"
            v-if="$store.state.auth.user.isEsa || can('createAcls')"
          >
            <Button icon="pi pi-plus" @click="newAcl()" />
            <Button
              v-if="can('seeAclsCategories') || $store.state.auth.user.isEsa"
              label="Categorias"
              class="p-ml-1"
              icon="pi pi-tag"
              @click="$router.push(`/acls-categories`)"
            />
          </div>
        </template>
        <Column
          field="name"
          filterField="name"
          header="Nome"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="alias"
          filterField="alias"
          header="Alias"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="category_id"
          header="Categoria"
          filterField="category_id"
          sortable
          filterMatchMode="contains"
        >
          <template #body="slotProps">
            {{ slotProps.data.category.name }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="listCategories"
              optionLabel="name"
              optionValue="id"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column
          field="comment"
          header="Comentário"
          filterField="comment"
          sortable
          filterMatchMode="contains"
        >
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="active"
          header="Activa"
          sortable
          filterField="active"
          dataType="boolean"
        >
          <template #body="slotProps">
            <span class="p-column-title">Activa:</span>
            {{ slotProps.data.active == 1 ? "Sim" : "Não" }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { value: 0, label: 'Não' },
                { value: 1, label: 'Sim' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
            >
            </Dropdown>
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button
              v-if="$store.state.auth.user.isEsa || can('updateAcls')"
              icon="pi pi-pencil"
              v-tooltip.left="'Editar'"
              class="p-button-sm p-button-rounded p-button-success"
              @click="editAcl(slotProps.index, slotProps.data)"
            />
            <Button
              v-if="$store.state.auth.user.isEsa || can('manageGroupAcls')"
              icon="pi pi-th-large"
              v-tooltip.left="'Adicionar Acl a grupos'"
              class="p-button-sm p-button-rounded p-button-info p-ml-1"
              @click="addToGroups(slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog
      :visible.sync="aclModel.showForm"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="(aclModel.id == undefined ? 'Criar' : 'Actualizar') + ' ACL'"
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form name="aclCtegoryForm" class="p-col-12" @submit.prevent="saveAcl">
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="aclModel.name"
                v-bind:class="[
                  { 'p-invalid': errors.has('aclName') },
                  'form-control',
                ]"
                :name="'aclName'"
              />
              <label for="aclName">Nome da Acl</label>
            </span>
            <small v-if="errors.has('aclName')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                :disabled="aclModel.id"
                type="text"
                v-validate="'required'"
                v-model="aclModel.alias"
                v-bind:class="[
                  { 'p-invalid': errors.has('aclAlias') },
                  'form-control',
                ]"
                :name="'aclAlias'"
              />
              <label for="aclAlias">Alias da Acl</label>
            </span>
            <small v-if="errors.has('aclAlias')" class="p-error" role="alert">
              Alias é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <dropdown
                v-model="aclModel.categoryId"
                v-validate="'required'"
                :options="listCategories"
                optionLabel="name"
                optionValue="id"
                :filter="true"
                :showClear="true"
                :name="'category'"
                v-bind:class="[
                  { 'p-invalid': errors.has('category') },
                  'form-control',
                ]"
              />
              <label for="category">Categoria</label>
            </span>
            <small v-if="errors.has('category')" class="p-error" role="alert">
              Categoria é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="aclModel.comment"
                v-bind:class="['form-control']"
                :name="'comment'"
              />
              <label for="comment">Comentário</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2" v-if="aclModel.id">
            <span
              class="p-float-label"
              style="top: -0.75rem; left: -1rem; font-size: 12px"
            >
              <label for="admission">Ativa</label>
            </span>
            <InputSwitch
              v-model="aclModel.active"
              name="admission"
              style="'top: 3px;"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveAcl"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeAclForm"
        />
      </template>
    </Dialog>
    <Dialog
      :visible.sync="groupAcls.show"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        'Adicionar a grupos a ACL:  ' +
        (groupAcls.acl == undefined ? '' : groupAcls.acl.name)
      "
      :modal="true"
      :contentStyle="{ overflow: 'auto' }"
    >
      <AclsToGroup
        :acl="groupAcls.acl"
        v-on:cancel="cancelSetGroups"
        v-on:setAclGroupsOk="cancelSetGroups"
      />
    </Dialog>
  </div>
</template>
<script>
import AclsToGroup from "../components/acls/setAclToGroups.vue";
import "vue-loading-overlay/dist/vue-loading.css";
import aclsService from "../services/acls.service";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "Acls",
  components: {
    AclsToGroup,
  },
  data() {
    return {
      loading: true,
      listAcls: [],
      listCategories: [],
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        alias: { value: null, matchMode: FilterMatchMode.CONTAINS },
        category_id: { value: null, matchMode: FilterMatchMode.EQUALS },
        comment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      aclModel: { showForm: false },
      groupAcls: { show: false },
    };
  },
  async created() {
    this.listAcls = await aclsService.getAcls();
    this.listCategories = await this.getCategoriesList();
    this.loading = false;
  },
  methods: {
    getCategoriesList() {
      return aclsService.getCategories().then((response) => {
        response = response.filter((category) => category.active == 1);
        return response;
      });
    },
    closeAclForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.aclModel = { showForm: false });
    },
    newAcl() {
      return (this.aclModel.showForm = true);
    },
    editAcl(index, acl) {
      return (this.aclModel = {
        index: index,
        showForm: true,
        id: acl.id,
        name: acl.name,
        alias: acl.alias,
        categoryId: acl.category_id,
        comment: acl.comment,
        active: acl.active == 1 ? true : false,
        acl: acl,
      });
    },
    saveAcl() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.aclModel.id === undefined) {
          return this.saveNewAcl();
        }

        return this.updateAcl();
      });
    },
    saveNewAcl() {
      let bodyParams = {
        name: this.aclModel.name,
        alias: this.aclModel.alias,
        category_id: this.aclModel.categoryId,
      };

      if (this.aclModel.active !== undefined) {
        bodyParams.active = this.aclModel.active;
      }
      if (this.aclModel.comment !== undefined) {
        bodyParams.comment = this.aclModel.comment;
      }

      return aclsService.addAcl(bodyParams).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao inserir a acl",
            life: 3000,
          });
        }

        this.listAcls.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Acl criada",
          detail: "A Acl foi criada com sucesso",
          life: 3000,
        });
        return this.closeAclForm();
      });
    },
    updateAcl() {
      let bodyParams = {
        name: this.aclModel.name,
        alias: this.aclModel.alias,
        category_id: this.aclModel.categoryId,
      };

      if (this.aclModel.active !== undefined) {
        bodyParams.active = this.aclModel.active;
      }
      if (this.aclModel.comment !== undefined) {
        if (this.aclModel.comment == null) {
          bodyParams.comment = "";
        } else {
          bodyParams.comment = this.aclModel.comment;
        }
      }
      return aclsService
        .updateAcl(this.aclModel.id, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizar a acl",
              life: 3000,
            });
          }
          this.listAcls.splice(this.aclModel.index, 1);
          this.listAcls[this.aclModel.index] = response;
          this.$toast.add({
            severity: "success",
            summary: "Acl atualizada",
            detail: "A Acl foi atualizado com sucesso",
            life: 3000,
          });
          return this.closeAclForm();
        });
    },
    addToGroups(acl) {
      this.groupAcls.acl = acl;

      return (this.groupAcls.show = true);
    },
    cancelSetGroups() {
      return (this.groupAcls = { show: false });
    },
  },
};
</script>
